import classNames from 'classnames'
import React from 'react'

import AiRobotIcon from '../../assets/icons/ai-robot.svg'
import AiRobot2Icon from '../../assets/icons/ai-suggestion-white.svg'
import ArrowBackIcon from '../../assets/icons/arrow-back2.svg'
import ArrowForwardIcon from '../../assets/icons/arrow-forward.svg'
import ArrowRevertIcon from '../../assets/icons/arrow-revert.svg'
import ArrowMenuIcon from '../../assets/icons/arrow.svg'
import BurgerIcon from '../../assets/icons/burger.svg'
import CanvasLogo from '../../assets/icons/canvas/logo.svg'
import ChordDetailsIcon from '../../assets/icons/chord-details.svg'
import ChordEditIcon from '../../assets/icons/chord-edit.svg'
import ChordsPaletteIcon from '../../assets/icons/chords-palette.svg'
import CloneIcon from '../../assets/icons/clone.svg'
import Close from '../../assets/icons/close-white.svg'
import DiceIcon from '../../assets/icons/dice.svg'
import DotsMenuIcon from '../../assets/icons/dots-menu-vtwo.svg'
import Drums2Icon from '../../assets/icons/drum.svg'
import DrumsIcon from '../../assets/icons/drums.svg'
import ExportAudioIcon from '../../assets/icons/export-audio.svg'
import ExportTextIcon from '../../assets/icons/export-text.svg'
import ExportIcon from '../../assets/icons/export.svg'
import ExternalLinkIcon from '../../assets/icons/external-link.svg'
import FileOfflineIcon from '../../assets/icons/file-offline.svg'
import FileSavedIcon from '../../assets/icons/file-saved.svg'
import FileIcon from '../../assets/icons/file.svg'
import GuitarFretIcon from '../../assets/icons/guitar-fret.svg'
import GuitarIcon from '../../assets/icons/guitar.svg'
import HandWavingIcon from '../../assets/icons/hand-waving.svg'
import InstrumentsIcon from '../../assets/icons/instruments.svg'
import LaunchpadIcon from '../../assets/icons/launchpad.svg'
import LoaderIcon from '../../assets/icons/loader.svg'
import LoadingIcon from '../../assets/icons/loading.svg'
import LockIcon from '../../assets/icons/lock-ai.svg'
import UnlockIcon from '../../assets/icons/lock-off.svg'
import MicrophoneIcon from '../../assets/icons/microphone.svg'
import NotebookIcon from '../../assets/icons/notebook.svg'
import PencilIcon from '../../assets/icons/pencil.svg'
import PianoIcon from '../../assets/icons/piano-plain.svg'
import PianoRollIcon from '../../assets/icons/piano-roll.svg'
import PlatinumIcon from '../../assets/icons/platinum.svg'
import PlaySmall from '../../assets/icons/play-small.svg'
import PlusIcon from '../../assets/icons/plus.svg'
import PremiumIcon from '../../assets/icons/premium.svg'
import RandomIcon from '../../assets/icons/random.svg'
import RegenerateIcon from '../../assets/icons/regenerate.svg'
import Repeat2Icon from '../../assets/icons/repeat2.svg'
import RepeatIcon from '../../assets/icons/repeat.svg'
import Share from '../../assets/icons/share.svg'
import SidebarLyrics from '../../assets/icons/sidebar-lyrics.svg'
import SidebarOnboarding from '../../assets/icons/sidebar-onboarding.svg'
import StarIcon from '../../assets/icons/star.svg'
import TrashIcon from '../../assets/icons/trash.svg'
import UploadIcon from '../../assets/icons/upload.svg'
import UserIcon from '../../assets/icons/user.svg'
import VoicingsIcon from '../../assets/icons/voicings.svg'
import ZoomInIcon from '../../assets/icons/zoom-in.svg'
import ZoomOutIcon from '../../assets/icons/zoom-out.svg'
import CircleLoader from './CircleLoader/CircleLoader'
import styles from './IconMenuButton.module.scss'
import Link from './Link/Link'

const iconTypes = [
  'file',
  'file-saved',
  'file-offline',
  'loading',
  'save',
  'random',
  'arrow-forward',
  'arrow-back',
  'arrow-menu',
  'arrow-revert',
  'export',
  'platinum',
  'platinum-img',
  'clone',
  'pencil',
  'dice',
  'loader',
  'trash',
  'none',
  'piano',
  'export-audio',
  'export-text',
  'play-small',
  'zoom-in',
  'zoom-out',
  'user',
  'burger',
  'notebook',
  'chord-details',
  'chord-edit',
  'drums',
  'drums2',
  'hand-waving',
  'external-link',
  'ai-robot',
  'ai-robot-2',
  'star',
  'plus',
  'launchpad',
  'piano-roll',
  'microphone',
  'delete-account',
  'repeat2',
  'dots-menu',
  'regenerate',
  'repeat',
  'canvas-logo',
  'guitar',
  'close',
  'share',
  'chords-palette',
  'voicings',
  'lock',
  'unlock',
  'instruments',
  'guitar-fret',
  'sidebar-lyrics',
  'sidebar-onboarding',
  'premium',
] as const
export type IconType = typeof iconTypes[number]

type Props = {
  type?: 'icon' | 'iconText' | 'text'
  dataTooltipId?: string
  className?: string
  blank?: boolean
  enabled?: boolean
  responsive?: boolean
  isHoverWhite?: boolean
  isHoverBg?: boolean
  loading?: boolean
  loadingIcon?: boolean
  loaderClassname?: string
  selected?: boolean
  icon?: IconType
  text?: string | JSX.Element
  subContent?: JSX.Element
  onClick?: (e?: any) => void
  href?: string
  blur?: boolean
  onMouseDown?: (e?: any) => void
  onMouseEnter?: (e?: any) => void
  onMouseLeave?: (e?: any) => void
}

const GetIcon = (icon?: IconType) => {
  switch (icon) {
    case 'file':
      return <FileIcon className={styles.icon} />
    case 'file-saved':
      return <FileSavedIcon className={styles.icon} />
    case 'loading':
      return <LoadingIcon className={styles.icon} />
    case 'file-offline':
      return <FileOfflineIcon className={styles.icon} />
    case 'save':
      return <UploadIcon className={styles.icon} />
    case 'play-small':
      return <PlaySmall className={styles.icon} />
    case 'pencil':
      return <PencilIcon className={styles.icon} />
    case 'arrow-revert':
      return <ArrowRevertIcon className={styles.icon} />
    case 'drums':
      return <DrumsIcon className={styles.icon} />
    case 'drums2':
      return <Drums2Icon className={styles.icon} />
    case 'user':
      return <UserIcon className={styles.icon} />
    case 'dice':
      return <DiceIcon className={styles.icon} />
    case 'loader':
      return <LoaderIcon className={styles.icon} />
    case 'random':
      return <RandomIcon className={styles.icon} />
    case 'regenerate':
      return <RegenerateIcon className={styles.icon} />
    case 'hand-waving':
      return <HandWavingIcon className={styles.icon} />
    case 'trash':
      return <TrashIcon className={styles.icon} />
    case 'chords-palette':
      return <ChordsPaletteIcon className={styles.icon} />
    case 'voicings':
      return <VoicingsIcon className={styles.icon} />
    case 'zoom-in':
      return <ZoomInIcon className={styles.icon} />
    case 'zoom-out':
      return <ZoomOutIcon className={styles.icon} />
    case 'piano-roll':
      return <PianoRollIcon className={styles.icon} />
    case 'external-link':
      return <ExternalLinkIcon className={styles.icon} />
    case 'piano':
      return <PianoIcon className={styles.icon} />
    case 'clone':
      return <CloneIcon className={styles.icon} />
    case 'guitar':
      return <GuitarIcon className={styles.icon} />
    case 'burger':
      return <BurgerIcon className={styles.icon} />
    case 'arrow-forward':
      return <ArrowForwardIcon className={styles.icon} />
    case 'arrow-back':
      return <ArrowBackIcon className={styles.icon} />
    case 'arrow-menu':
      return <ArrowMenuIcon className={styles.icon} />
    case 'export':
      return <ExportIcon className={styles.icon} />
    case 'platinum':
      return <PlatinumIcon className={styles.icon} />
    case 'platinum-img':
      return <img src={'/static/images/disk/multi_platinum.png'} alt='bg' className={styles.icon} />
    case 'premium':
      return <PremiumIcon className={styles.icon} />
    case 'export-audio':
      return <ExportAudioIcon className={styles.icon} />
    case 'export-text':
      return <ExportTextIcon className={styles.icon} />
    case 'notebook':
      return <NotebookIcon className={styles.icon} />
    case 'chord-details':
      return <ChordDetailsIcon className={styles.icon} />
    case 'chord-edit':
      return <ChordEditIcon className={styles.icon} />
    case 'ai-robot':
      return <AiRobotIcon className={styles.icon} />
    case 'ai-robot-2':
      return <AiRobot2Icon className={styles.icon} />
    case 'star':
      return <StarIcon className={styles.icon} />
    case 'plus':
      return <PlusIcon className={styles.icon} />
    case 'microphone':
      return <MicrophoneIcon className={styles.icon} />
    case 'launchpad':
      return <LaunchpadIcon className={styles.icon} />
    case 'delete-account':
      return <></>
    case 'repeat2':
      return <Repeat2Icon className={styles.icon} />
    case 'dots-menu':
      return <DotsMenuIcon className={styles.icon} />
    case 'repeat':
      return <RepeatIcon className={styles.icon} />
    case 'canvas-logo':
      return <CanvasLogo className={styles.icon} />
    case 'close':
      return <Close className={styles.icon} />
    case 'share':
      return <Share className={styles.icon} />
    case 'lock':
      return <LockIcon className={styles.icon} />
    case 'unlock':
      return <UnlockIcon className={styles.icon} />
    case 'instruments':
      return <InstrumentsIcon className={styles.icon} />
    case 'guitar-fret':
      return <GuitarFretIcon className={styles.icon} />
    case 'sidebar-lyrics':
      return <SidebarLyrics className={styles.icon} />
    case 'sidebar-onboarding':
      return <SidebarOnboarding className={styles.icon} />
    default:
      return <></>
  }
}

const IconMenuButton: React.FC<Props> = (props) => {
  const classes = [styles.container, props.className]

  if (props.isHoverWhite) {
    classes.push(styles.isHoverWhite)
  }
  if (props.isHoverBg) {
    classes.push(styles.isHoverBg)
  }
  if (props.enabled) {
    classes.push(styles.enabled)
  }
  if (props.blur) {
    classes.push(styles.blur)
  }

  if (props.responsive) {
    classes.push(styles.responsive)
  }
  if (props.icon === 'none') {
    classes.push(styles.textOnly)
  }
  if (props.href && props.enabled) {
    return (
      <Link
        href={props.href}
        target={props.blank ? '_blank' : undefined}
        rel={props.blank ? 'noreferrer' : undefined}
        className={classes.join(' ')}
        data-disabled={!props.enabled}
        data-selected={props.selected}
        data-loading={props.loading}
      >
        {GetIcon(props.icon)}
        {props.text !== '' && (props.type === 'iconText' ? props.text : <span>{props.text}</span>)}
      </Link>
    )
  }
  return (
    <div
      onClick={props.enabled ? props.onClick : () => void 0}
      onMouseDown={props.enabled ? props.onMouseDown : () => void 0}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={classes.join(' ')}
      data-disabled={!props.enabled}
      data-selected={props.selected}
      data-loading={props.loading}
      data-tooltip-id={props.dataTooltipId}
    >
      {props.loadingIcon ? (
        <div className={classNames(styles.loader, props.loaderClassname)}>
          <CircleLoader />
        </div>
      ) : (
        GetIcon(props.icon)
      )}
      {props.text !== '' && (props.type === 'iconText' ? props.text : <span>{props.text}</span>)}
      {props.subContent || null}
    </div>
  )
}

IconMenuButton.defaultProps = {
  className: '',
  enabled: true,
  responsive: true,
  isHoverWhite: false,
  isHoverBg: false,
  icon: 'none',
  text: '',
  selected: false,
  onClick: () => void 0,
}

export default IconMenuButton
