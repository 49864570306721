import React, { ReactNode } from 'react'

import useRootSizes from '../hooks/useRootSizes'

type FetchType = {
  isMobile: boolean | null
  isTablet: boolean
  isSmallTablet: boolean
  isSmallDesktop: boolean
}

export const UseSizes = React.createContext<FetchType>({
  isMobile: false,
  isTablet: false,
  isSmallTablet: false,
  isSmallDesktop: false,
})

export const SizesProvider = ({ children }: { children: ReactNode }) => {
  const sizes = useRootSizes()
  return (
    <UseSizes.Provider
      value={{
        isMobile: sizes.isMobile,
        isTablet: !!sizes.isTablet,
        isSmallTablet: !!sizes.isSmallTablet,
        isSmallDesktop: !!sizes.isSmallDesktop,
      }}
    >
      {children}
    </UseSizes.Provider>
  )
}

const useSizes = () => React.useContext<FetchType>(UseSizes)

export default useSizes
