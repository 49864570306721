export const trackPixelEvent = (event: string, data?: any) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track(event, data)
    })
}

export const trackPixelCustomEvent = (event: string, data?: any) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track(event, data)
    })
}
