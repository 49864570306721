import classNames from 'classnames'
import React from 'react'

import styles from './CircleLoader.module.scss'

const CircleLoader = ({ className }: { className?: string }) => {
  return <div className={classNames(styles.container, className)} />
}

export default CircleLoader
