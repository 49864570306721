import { useCallback, useEffect, useState } from 'react'

import { isInStandaloneMode } from '../utils/nextUtils'

export default function useRootSizes() {
  const [mobileTargetReached, setMobileTargetReached] = useState<boolean | null>(null)
  const [smallTabletTargetReached, setSmallTabletTargetReached] = useState<boolean | null>(null)
  const [tabletTargetReached, setTabletTargetReached] = useState<boolean | null>(null)
  const [smallDesktopTargetReached, setSmallDesktopTargetReached] = useState<boolean | null>(null)

  const updateMobileTarget = useCallback((e: any) => {
    if (e.matches || isInStandaloneMode()) {
      setMobileTargetReached(true)
    } else {
      setMobileTargetReached(false)
    }
  }, [])

  const updateSmallTabletTarget = useCallback((e: any) => {
    if (e.matches) {
      setSmallTabletTargetReached(true)
    } else {
      setSmallTabletTargetReached(false)
    }
  }, [])

  const updateTabletTarget = useCallback((e: any) => {
    if (e.matches) {
      setTabletTargetReached(true)
    } else {
      setTabletTargetReached(false)
    }
  }, [])

  const updateSmallDesktopTarget = useCallback((e: any) => {
    if (e.matches) {
      setSmallDesktopTargetReached(true)
    } else {
      setSmallDesktopTargetReached(false)
    }
  }, [])

  useEffect(() => {
    const mediaMobile = window.matchMedia(`(max-width: ${576}px)`)
    mediaMobile.addListener(updateMobileTarget)

    if (mediaMobile.matches || isInStandaloneMode()) {
      setMobileTargetReached(true)
    } else {
      setMobileTargetReached(false)
    }

    return () => mediaMobile.removeListener(updateMobileTarget)
  }, [])

  useEffect(() => {
    const mediaTablet = window.matchMedia(`(max-width: ${1100}px)`)
    mediaTablet.addListener(updateTabletTarget)
    if (mediaTablet.matches) {
      setTabletTargetReached(true)
    } else {
      setTabletTargetReached(false)
    }

    return () => mediaTablet.removeListener(updateTabletTarget)
  }, [])

  useEffect(() => {
    const mediaTablet = window.matchMedia(`(max-width: ${800}px)`)
    mediaTablet.addListener(updateSmallTabletTarget)
    if (mediaTablet.matches) {
      setSmallTabletTargetReached(true)
    } else {
      setSmallTabletTargetReached(false)
    }

    return () => mediaTablet.removeListener(updateSmallTabletTarget)
  }, [])

  useEffect(() => {
    const mediaTablet = window.matchMedia(`(max-width: ${1800}px)`)
    mediaTablet.addListener(updateSmallDesktopTarget)
    if (mediaTablet.matches) {
      setSmallDesktopTargetReached(true)
    } else {
      setSmallDesktopTargetReached(false)
    }

    return () => mediaTablet.removeListener(updateSmallDesktopTarget)
  }, [])

  return {
    isMobile: mobileTargetReached,
    isTablet: tabletTargetReached,
    isSmallTablet: smallTabletTargetReached,
    isSmallDesktop: smallDesktopTargetReached,
  }
}
